import {graphql, withPrefix} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {Button} from '../../../components/button';
import {CaseHeader} from '../../../components/case_header';
import {WillAnimate} from '../../../components/content_animate';
import {Layout} from '../../../components/layout';
import {ScrollTo} from '../../../components/scroll_to';

const locale = 'en';
const translatedPagePath = '/nl/portfolio/taxatieplatform-taxapi/';

export default ({data}: any) => (
    <Layout
        title="Case: TaXapi valuation platform – Modern real estate valuation – Showcase of our work"
        description="Together with TaXapi we develop an innovative progressive web application that makes all the work of the real estate valuer fun and easy to perform."
        ogImage="taxapi.png"
        navColor="white"
        locale={locale}
        translatedPagePath={translatedPagePath}>
        <CaseHeader
            urlBack="/en/portfolio/translation-platform-fairlingo/"
            titleBack="Case: Translation platform Fairlingo"
            urlForward="/en/portfolio/saas-product-keeping/"
            titleForward="Case: Our own product: Keeping"
            locale={locale}
            translatedPagePath={translatedPagePath}>
            <Img className="hero__image" alt="RP3" fluid={data.taxapi.childImageSharp.fluid} critical />
            <div className="hero__content-wrapper">
                <div className="hero__content">
                    <h1>
                        {/* Make sure to have the correct spacing when br becomes invisible */}
                        Modern real
                        <br />
                        estate valuation
                    </h1>
                    <h2>TaXapi valuation platform</h2>
                </div>
            </div>
            <ScrollTo className="hero__scroll" target="/en/portfolio/valuation-platform-taxapi/#from-hero" />
        </CaseHeader>

        <section className="section">
            {/* Case intro */}
            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display content__display--intro">
                        <h2>
                            With its innovative web application, TaXapi is leading the way towards a new,{' '}
                            <span className="highlight">faster</span> and better{' '}
                            <span className="highlight">valuation process</span>, for all valuers and customers.
                        </h2>

                        <ul className="list" style={{marginTop: '3rem'}}>
                            <li className="ion-md-medal gray">
                                Computable Awards: nominated within category{' '}
                                <a
                                    title="Computable Awards: nomination for valuation platform TaXapi (Dutch)"
                                    href="https://www.computable.nl/artikel/informatie/awards-nieuws/6669955/1853296/taxapi-transformeert-taxatieproces-vastgoed.html"
                                    target="_blank"
                                    rel="noreferrer">
                                    best IT&ndash;project for SME
                                </a>
                                .
                            </li>
                            <li className="ion-md-medal gray">
                                Dutch Interactive Awards: nominated within category{' '}
                                <a
                                    title="Dutch Interactive Awards: nomination for valuation platform TaXapi (Dutch)"
                                    href="http://www.dutchinteractiveawards.nl/nominatie/modern-vastgoed-taxeren-taxatieplatform-taxapi-label305/"
                                    target="_blank"
                                    rel="noreferrer">
                                    service
                                </a>
                                .
                            </li>
                        </ul>
                    </div>
                </div>
            </WillAnimate>

            {/* Case assignment */}
            <WillAnimate as="article" id="from-hero" className="content">
                <div className="container">
                    <div className="content__display content__display--right">
                        <h2>The assignment</h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            The founders of TaXapi had many years’ experience as valuers and knew more than anyone about
                            the challenges involved in the valuation process.
                        </p>
                        <p>
                            TaXapi asked us to convert this <span className="highlight">innovative</span> idea into an
                            online platform that was easy for both valuers and customers to use.
                        </p>
                        <p>
                            We joined forces to meet the challenge of redefining the valuation process. TaXapi wanted to
                            ensure that valuers were able to spend <span className="highlight">more time</span> on the
                            house they were valuing and less time on associated paperwork and administration.
                        </p>

                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Product design and advice</li>
                            <li className="ion-md-arrow-dropright">UX and UI design</li>
                            <li className="ion-md-arrow-dropright">Web development (PWA)</li>
                            <li className="ion-md-arrow-dropright">Integration with external services</li>
                        </ul>
                    </div>
                </div>
            </WillAnimate>

            {/* Case challenge */}
            <WillAnimate as="article" className="content content--coloured">
                <div className="container">
                    <div className="content__display">
                        <h2>The challenge</h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            Preparing a valuation report requires the valuer to answer hundreds of questions about the
                            property manually. The biggest challenge was using algorithms to complete as many of these
                            questions as possible <span className="highlight">automatically</span> with the correct
                            data.
                        </p>
                        <p>
                            To achieve this, the TaXapi web application needs to link to various external services, such
                            as the Dutch Land Registry Office, to obtain automated data about the property for a
                            valuation.
                        </p>
                        <p>
                            The huge volume of questions about the property being valued also needs to be properly
                            ordered and presented to the valuer in a logical,{' '}
                            <span className="highlight">time-saving</span> way. Achieving this requires efficient
                            cooperation between user interface and user experience.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="div" className="content">
                <div className="image">
                    <div className="image__content">
                        <Img
                            alt="Examples of screens from the TaXapi web application"
                            fluid={data.taxapiScreens.childImageSharp.fluid}
                        />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            The progressive web application can be used as an app on mobile devices but also on the
                            computer via a browser. Key elements are even available entirely offline.
                        </span>
                    </div>
                </div>
            </WillAnimate>

            {/* Article idea and challenges description */}

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Working <br />
                            together <br />
                            to create
                        </h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            The former valuers at TaXapi perform the role of{' '}
                            <span className="highlight">product owner</span> perfectly. They review the priorities in
                            the backlog continually, both during the initial development before the product launch and
                            now that we are working together to develop the product further.
                        </p>
                        <p>
                            In the first development period, we worked with TaXapi in a race to achieve{' '}
                            <span className="highlight">concept validation</span> to measure interest in the product. To
                            do this we developed a concept version of the final product that included the essential
                            features. This was <span className="highlight">extensively tested</span> in several sessions
                            with a group of 20 valuers, before the first public launch.
                        </p>
                        <p>
                            Following an enthusiastic reception from the valuation world, we continued with weekly
                            iterations to achieve improved versions with{' '}
                            <span className="highlight">interim deliveries</span>. This streamlined the process for
                            testing the software in the real world with valuers.
                        </p>
                        <p>
                            During the early stages of development, the clients provided valuable insights from the user
                            perspective based on their years of valuation experience. Testing with the group of real
                            users also benefited the platform during the initial development process. Our{' '}
                            <span className="highlight">agile approach</span> is ideal for development processes such as
                            the one for TaXapi.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content-group">
                        <div className="content-group__background content-group__background--right" />
                        <div className="content__image">
                            <div className="image image--detail">
                                <div className="image__content">
                                    <Img
                                        alt="Select the number of floors in the TaXapi interface"
                                        fluid={data.taxapiHuisSamenstellen.childImageSharp.fluid}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="content__copy">
                            <h2>Working efficiently with TaXapi</h2>
                            <p>
                                The user interface in the web application is designed to prioritise{' '}
                                <span className="highlight">efficiency</span> for the valuer. All the actions are
                                carried out step by step, progress is always saved and everything works without an
                                internet connection.
                            </p>
                            <p>
                                Progress is recorded for hundreds of questions in total, so that the valuer can go on
                                completing the valuation at <span className="highlight">any time</span>. Alongside this,
                                the fixed progress bar shows how the valuation is progressing.
                            </p>
                            <p>
                                The mobile nature of the web application means the valuer is able to work anywhere, so
                                he has more time for valuing and doesn’t have to spend as much time in the office.
                            </p>
                        </div>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__image content__image--right">
                        <div className="image image--detail">
                            <div className="image__content">
                                <video
                                    width="100%"
                                    controls
                                    poster={withPrefix('/images/taxapi-video-placeholder.jpg')}>
                                    <source src={withPrefix('/video/taxapi.mp4')} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>

                    <div className="content__copy">
                        <h2>Smart integrations with external services</h2>
                        <p>
                            As a valuer you’re often responsible for gathering together information about the property
                            being valued from different sources. In the TaXapi web application we have automated this
                            process as much as possible.
                        </p>
                        <p>
                            The application is able to collect the data in advance from at least 11 different external
                            sources. Reference properties are also selected{' '}
                            <span className="highlight">automatically</span>, based on the characteristics of the
                            property currently being valued.
                        </p>
                        <p>
                            All this takes place without any action by the valuer, reducing the valuer’s workload. It
                            also <span className="highlight">saves</span> a huge amount of time.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content-group">
                        <div className="content-group__background content-group__background--right" />
                        <div className="content__image">
                            <div className="image image--detail">
                                <div className="image__content">
                                    <Img alt="TaXapi valuation" fluid={data.taxapiOverzicht.childImageSharp.fluid} />
                                </div>
                            </div>
                        </div>

                        <div className="content__copy">
                            <h2>Streamlining the valuation process</h2>
                            <p>
                                The web application is set up to keep the valuer and the customer updated in{' '}
                                <span className="highlight">real time</span> about the stage the valuation is at.
                            </p>
                            <p>
                                This means the valuer is able to view in real time what still needs to be done for the
                                valuation of the property concerned. It also enables both parties to use information
                                from the process to complete preparatory work in advance.
                            </p>
                            <p>
                                Once the last stage of the valuation has been reached, the customer receives a message
                                stating that the valuation is ready to be <span className="highlight">downloaded</span>.
                                As soon as payment has been made using the iDEAL online payment method, the PDF file is
                                released for download. The valuer and the customer have completed an intensive process{' '}
                                <span className="highlight">with ease</span>, directly in the web application.
                            </p>
                        </div>
                    </div>
                </div>
            </WillAnimate>

            {/* Article image and role  */}

            <WillAnimate as="article" className="content">
                <div className="image">
                    <div className="image__content">
                        <Img
                            alt="Customers of TaXapi who use the web application."
                            fluid={data.taxapiCouple.childImageSharp.fluid}
                        />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            With TaXapi the entire valuation process is made visible to the customer. The customer
                            always has access to all the information.
                        </span>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Want to <br />
                            create a <br />
                            platform?
                        </h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            TaXapi has introduced innovation to the Dutch valuation market with this new platform. Even
                            more importantly&mdash;we built up a{' '}
                            <span className="highlight">long-term relationship</span> in the process. If you have an
                            interesting idea for a software product then get in touch with{' '}
                            <span className="inline-avatar">
                                <Img alt="Olav" fixed={data.olav.childImageSharp.fixed} Tag="span" />
                                <span>Olav</span>
                            </span>{' '}
                            to discuss your plans.
                        </p>
                    </div>
                    <div className="content__buttons content__buttons--50">
                        <Button url="/nl/contact/" name="Contact us" title="Contact information Enschede" />
                    </div>
                </div>
            </WillAnimate>
        </section>
    </Layout>
);

export const query = graphql`
    query {
        taxapi: file(relativePath: {eq: "portfolio/taxapi/taxapi-main.jpg"}) {
            ...fullscreenHero
        }

        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }

        taxapiScreens: file(relativePath: {eq: "portfolio/taxapi/taxapi-screens.jpg"}) {
            ...fluid1200Width
        }
        taxapiHuisSamenstellen: file(relativePath: {eq: "portfolio/taxapi/taxapi-huis-samenstellen.png"}) {
            ...fluid1200Width
        }
        taxapiOverzicht: file(relativePath: {eq: "portfolio/taxapi/taxapi-status.png"}) {
            ...fluid800Width
        }
        taxapiCouple: file(relativePath: {eq: "portfolio/taxapi/taxapi-couple.jpg"}) {
            ...fluid800Width
        }
    }
`;
